// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2021_10-module--maineDigest--3+iku";
export var heroImage = "maine_digest_2021_10-module--heroImage--i4IXl";
export var columnWrapper = "maine_digest_2021_10-module--columnWrapper--hoZz9";
export var headingLogo = "maine_digest_2021_10-module--headingLogo--k+WLW";
export var headingWrapper = "maine_digest_2021_10-module--headingWrapper--1NXXF";
export var heading = "maine_digest_2021_10-module--heading--2MBul";
export var instance = "maine_digest_2021_10-module--instance--2kY+g";
export var subheading = "maine_digest_2021_10-module--subheading--4-EB1";
export var textWrapper = "maine_digest_2021_10-module--textWrapper--BmfWg";
export var latestnewsarticleheadline = "maine_digest_2021_10-module--latestnewsarticleheadline--i91pP";
export var dateline = "maine_digest_2021_10-module--dateline--2DhM6";
export var whatsNew = "maine_digest_2021_10-module--whatsNew--HK4dh";
export var photosWrapper = "maine_digest_2021_10-module--photosWrapper--+Ngcp";
export var leftPhoto = "maine_digest_2021_10-module--leftPhoto--cXdOd";
export var leftPhotoCaption = "maine_digest_2021_10-module--leftPhotoCaption--flkN6";
export var rightPhotoCaption = "maine_digest_2021_10-module--rightPhotoCaption--a05yU";
export var rightPhoto = "maine_digest_2021_10-module--rightPhoto--dCtWT";
export var rightCaptions = "maine_digest_2021_10-module--rightCaptions--kOehL";
export var photoCaption = "maine_digest_2021_10-module--photoCaption--eoW2M";
export var afterCaption = "maine_digest_2021_10-module--afterCaption--was7k";